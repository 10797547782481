/* eslint-disable no-console */
import axios from 'axios';
import { API_URL, IP_LISTENER_URL } from 'config';
import { ValidationStatus } from 'types';

axios.defaults.withCredentials = true;

interface StatusResponse {
  // solana: ValidationStatus;
  discordValidation: ValidationStatus;
  emailValidation: ValidationStatus;
  githubValidation: ValidationStatus;
  twitterValidation: ValidationStatus;
  solanaValidation: ValidationStatus;
  ethereumValidation: ValidationStatus;
  referral: ValidationStatus;
  walletAddress: string;
  fireToken: ValidationStatus;
}

export const getFaucetStatus = async (walletAddress: string) => {
  const { data } = await axios.get<StatusResponse>(
    `${API_URL}/get-user-faucet-state/${walletAddress}`
  );
  return data;
};

export const verifyTwitter = async (walletAddress: string) => {
  await axios.post(`${API_URL}/twitter/submit-wallet-address`, {
    walletAddress,
  });
};

export const authenticateUser = async (
  captchaToken: string,
  walletAddress: string
) => {
  if (walletAddress) {
    await axios.get<StatusResponse>(
      `${API_URL}/verify-token?walletAddress=${walletAddress}`,
      {
        headers: {
          Authorization: `Bearer ${captchaToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }
};

export const verifyDiscord = async (code: string, walletAddress: string) => {
  const response = await axios.post(`${API_URL}/discord/OAUTHEndpoint`, {
    code,
    walletAddress,
  });
  if (response.data) {
    const userDiscordInfo = {
      discordName: response.data.discordName,
      discordEmail: response.data.discordEmail,
    };
    return userDiscordInfo;
  }
};

export const sendEmailCode = async (
  emailAddress: string,
  walletAddress: string
) => {
  await axios.post(`${API_URL}/email/receive-confirmation-code`, {
    emailAddress,
    walletAddress,
  });
};

export const submitEmailCode = async (
  verificationCode: string,
  walletAddress: string,
  emailAddress: string,
  utmSource: string | null
) => {
  await axios.post(`${API_URL}/email/submit-confirmation-code`, {
    verificationCode,
    walletAddress,
    emailAddress,
    utmSource,
  });
};

export const sendPhoneCode = async (
  mobileNo: string,
  walletAddress: string
) => {
  await axios.post(`${API_URL}/phone/receive-confirmation-code`, {
    mobileNo,
    walletAddress,
  });
};

export const submitPhoneCode = async (
  verificationCode: string,
  walletAddress: string
) => {
  await axios.post(`${API_URL}/phone/submit-confirmation-code`, {
    verificationCode,
    walletAddress,
  });
};

export const submitReferralCode = async (
  code: string,
  walletAddress: string
) => {
  await axios.post(`${API_URL}/redeemReferral`, {
    code,
    walletAddress,
  });
};

export const verifyGithub = async (code: string, walletAddress: string) => {
  const response = await axios.post(`${API_URL}/github/OAUTHEndpoint`, {
    code,
    walletAddress,
  });
  if (response.data) {
    const userGithubInfo = {
      githubName: response.data.githubName,
      githubEmail: response.data.githubEmail,
    };
    return userGithubInfo;
  }
};

// Export the asynchronous function as a constant
export const postUserVisit = async (walletAddress: string) => {
  try {
    // Fetch the user's IP address
    if (process.env.NODE_ENV !== 'development') {
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      if (!ipResponse.ok) {
        throw new Error('Failed get user visit details');
      }
      const ipData = await ipResponse.json();
      const userIp = ipData.ip;

      // Prepare the payload for the POST request
      const visitData = {
        ipAddress: userIp,
        publicKey: walletAddress,
      };
      // Post the user visit data to the server
      const visitResponse = await fetch(IP_LISTENER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(visitData),
      });

      if (!visitResponse.ok) {
        throw new Error('Failed to post visit data');
      }

      console.log('Visit data posted successfully');
    }
  } catch (error) {
    // Handle any errors that occur
    console.error('Error:', error);
  }
};

// get the rewards history for the user which claimed, not claimed, or Expired
export const getRewardsHistory = async (
  walletAddress: string,
  networkName: string
) => {
  const { data } = await axios.get(
    `${API_URL}/blockchain-rewards-config/${walletAddress}?network=${networkName}`,
    {
      // default is to throw error for anything other than 200
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    }
  );
  return data;
};

// post for claim the rewards of the blockchain ex Ethereum
export const postClaimRewards = async (
  chainPublicKey: string,
  koiiPublicKey: string,
  network: string
) => {
  const keyName = `${network.toLowerCase()}PublicKey`;

  const requestData = {
    [keyName]: chainPublicKey,
    koiiPublicKey: koiiPublicKey,
    network: network,
  };

  const { data } = await axios.post(`${API_URL}/bind-wallet`, requestData, {
    // default is to throw error for anything other than 200
    validateStatus: function (status) {
      return status < 500; // Resolve only if the status code is less than 500
    },
  });
  return data;
};
